import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import Auth from 'components/Auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, InputEmail, Loading } from '@yapay/design-system'
import 'static/css/Identification.css'
import {
  loginAuth,
  logout,
  updateUserContact,
  validateCPF,
  validatePhone,
  getCustomer,
  setOrder,
  getOrder,
  stepActive,
  changeLogin,
  removeLoginBalance,
  LOGGED_IN,
  USER_REGISTERED,
  USER_UNREGISTERED,
  RECEIVE_INFO,
  IDENTIFICATION_ERROR,
  changeUser,
} from 'actions'

export class Identification extends Component {
  state = {
    userEmail: '',
    showLoading: false,
    emailValid: undefined,
    transactionNotFound: false,
  }

  submitForm = event => {
    event.preventDefault()

    if (this.props.transaction.orderUndefined) return null

    const email = event.target.elements[0].value

    return this.redirectUser(email)
  }

  redirectUser = email => {
    const {
      dispatch,
      history,
      transaction: {
        type,
        order: { token },
      },
      user: { postAddress, continueWithPost, zip_code },
    } = this.props

    if (type === 't') {
      ReactGA.event({
        category: 'Identification',
        action: 'User Unregistered',
      })
      history.push('/cadastro')
      return
    }

    const data = { email, type, token, postAddress }
    return dispatch(getCustomer(data)).then(resp => {
      /* istanbul ignore else */
      if (resp.type === USER_REGISTERED) {
        ReactGA.event({
          category: 'Identification',
          action: 'User Registered',
        })

        if (continueWithPost) {
          if (zip_code !== '') {
            history.push('/endereco')
          } else {
            history.push('/cep')
          }
        } else {
          history.push('/confirma-endereco')
        }
      }

      /* istanbul ignore else */
      if (resp.type === USER_UNREGISTERED) {
        ReactGA.event({
          category: 'Identification',
          action: 'User Unregistered',
        })
        history.push('/cadastro')
      }

      if (resp.type === IDENTIFICATION_ERROR) {
        ReactGA.event({
          category: 'Identification',
          action: 'User email invalid',
        })

        this.setState({ showLoading: false, transactionNotFound: true })
      }

      return resp.type
    })
  }

  updateStateWithQueryParams() {
    const { dispatch, history } = this.props

    if (this.props.location.search !== '') {
      let searchParams = new URLSearchParams(this.props.location.search)
      let userData = {
        continueWithPost: true,
        hideChangeButton: true,
      }

      /** Transform params into object and check if empty
       * Example: name=John turn into { name: John }
       */
      for (let key of searchParams.keys()) {
        userData[key] = searchParams.get(key)
      }

      // lock cep if present
      userData.lockCep = userData.zip_code && userData.zip_code !== ''

      // add complement anyway
      userData.complement = searchParams.get('complement')

      // update the User state with incoming data
      dispatch(updateUserContact(userData))

      if (userData.email) {
        this.redirectUser(userData.email).then(resp => {
          return this.validateIncomingData(resp)
        })
      } else {
        history.push(window.location.pathname)
      }
    }
  }

  validateIncomingData = resp => {
    const { dispatch } = this.props
    const seller_token = this.props.transaction.seller.token

    /**
     * If the User isn't registered then we need to validade
     * the filled info and update the state of app
     */
    if (resp === USER_UNREGISTERED) {
      /* istanbul ignore else */
      if (this.props.user.cpf) {
        dispatch(
          validateCPF({
            cpf: this.props.user.cpf,
            seller_token,
          }),
        )
      }

      /* istanbul ignore else */
      if (this.props.user.phone) {
        dispatch(
          validatePhone({
            phone: this.props.user.phone,
            seller_token,
          }),
        )
      }
    }
  }

  loadOrder = (order, auth = null) => {
    const { dispatch } = this.props
    return dispatch(getOrder(order)).then(resp => {
      if (resp.type === RECEIVE_INFO) {
        if (auth) {
          this.loginWithToken(
            resp.data.buyer.email,
            resp.data.seller.token,
            order,
            auth,
          )
        } else if (order.type === 'o') {
          this.redirectUser(resp.data.buyer.email)
        } else {
          this.updateStateWithQueryParams()
        }
      }

      return resp
    })
  }

  componentDidMount() {
    const { token, type = 't', auth } = this.props.match.params
    const { dispatch } = this.props
    const order = { token, type }

    // update steps
    dispatch(stepActive(1))

    // reset global state if its not a POST
    if (!this.props.user.continueWithPost) dispatch(changeLogin())

    //in case of the user back to identification manually he'll be logged out
    if (this.props.login.session_token || this.props.user.session_token) {
      dispatch(removeLoginBalance())
      dispatch(logout())
      Auth.signout()
    }

    /**
     * if auth token present or definitive transaction,
     * show the loading, because it will load the user automatically
     */
    if (auth || type === 'o') this.setState({ showLoading: true })

    // if token present in url or its different from state
    if (token !== '' || token !== this.props.transaction.order.token) {
      // set the token into global state and tries to load the order
      dispatch(setOrder(order))
      return this.loadOrder(order, auth)
    }
  }

  loginWithToken(buyer_email, seller_token, order, auth) {
    /*
     * Tries to login with seller_token
     * and the session_token(auth)
     */

    let data = { seller_token, order, auth }
    return this.props.dispatch(loginAuth(data)).then(resp => {
      if (resp.type === LOGGED_IN) {
        /*
         * Update state to show the e-mail and
         * hide the loading component
         */
        this.setState({
          userEmail: buyer_email,
          showLoading: false,
        })

        /*
         * Authenticate the user
         * and after 3 seconds redirect to
         * update the address before pay
         */
        Auth.signIn(auth)
        Auth.isAuthenticated = true

        setTimeout(() => {
          this.redirectUser(buyer_email)
        }, 3000)
      } else {
        this.setState({
          userEmail: '',
          showLoading: false,
        })
      }
      return resp
    })
  }

  handleChange = event => {
    const { target } = event
    this.setState({ emailValid: undefined })
    this.props.dispatch(changeUser({ [target.name]: target.value }))
  }

  render() {
    const { search } = this.props.location
    const { text, showErrorMessage } = this.props.user
    ReactGA.pageview('/identificacao')

    if (this.state.showLoading || search !== '') {
      return (
        <div className="welcome">
          <Loading />
        </div>
      )
    }

    if (this.state.userEmail !== '') {
      return (
        <div className="welcome text-center">
          <div>
            <p className="welcome__text">Você está conectado com o e-mail</p>
            <h2 className="welcome__email">{this.state.userEmail}</h2>
          </div>
        </div>
      )
    }

    if (this.state.transactionNotFound === true) {
      return (
        <p className="text-red-400 text-center">
          {this.props.user.errorMessage}
        </p>
      )
    }

    return (
      <div className="flex-1">
        <h2>Identifique-se</h2>
        <p>
          Para identificarmos se você já é cliente <span>Vindi</span>, informe
          seu e-mail abaixo.
        </p>
        <form onSubmit={this.submitForm}>
          <InputEmail
            label="E-mail"
            id="email"
            name="email"
            valid={this.state.emailValid}
            onChange={this.handleChange}
            data-cy="email"
            required
          />
          {this.props.user.masked_email && (
            <p className="text-red-300 text-center">
              E-mail identificado {this.props.user.masked_email}
            </p>
          )}
          {showErrorMessage && (
            <p className="text-red-400 text-center">
              {this.props.user.errorMessage}
            </p>
          )}

          <div className="text-center">
            <Button
              color="info"
              type="submit"
              className="w-full md:w-auto"
              data-cy="continue-identification"
            >
              {text}
            </Button>
          </div>
        </form>
      </div>
    )
  }
}

Identification.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func,
  submitForm: PropTypes.func,
  checkingRegister: PropTypes.bool,
  transaction: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  stepActive: PropTypes.number,
}

const mapStateToProps = /* istanbul ignore next */ state => state

export default withRouter(connect(mapStateToProps)(Identification))
